import React from 'react'
import { Layout, Shipping, Seo } from '../components'

export default function ShippingAndHandling() {
  return (
    <Layout>
      <Seo title="Shipping And Handling" />
      <Shipping />
    </Layout>
  )
}
